/* Import Dashboard styles if needed */
@import url('./Dashboard.css');

.header-logged-in.media-item {
  background-color: rgb(32, 31, 31); /* Strong background color */
  border-radius: 10px;
  overflow: visible; /* Ensure overflow is visible */
  box-shadow: 0 4px 6px rgba(32, 31, 31, 0.1);
  margin-bottom: 30px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Match the max-width of the parent */
  margin: 0 auto;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 1rem;
}

.app-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

.header-right {
  margin-left: auto; /* This pushes the header-right to the far right */
}

.header-right .dropdown {
  position: relative;
}

/* New menu-button style */
.header-right .menu-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.header-right .menu-button:hover {
  background-color: #e65c50;
}

.header-right .menu-button svg {
  margin-right: 8px;
}

/* Clear the float to prevent layout issues */
.header-right::after {
  content: "";
  display: table;
  clear: both;
}

/* Existing suggest-button style (kept for reference) */
.header-right .suggest-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 5px;
}

.header-right .suggest-button:hover {
  background-color: #e65c50;
}

.header-right .dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
  min-width: 200px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  background-color: white;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.175);
}

.header-right .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

.header-right .dropdown-item:hover,
.header-right .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.header-right .dropdown-item.bold {
  font-weight: bold;
}

.header-right .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .header-logged-in.media-item,
  .header-content {
    max-width: 100%; 
  }
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    margin-top: 1rem;
    align-self: flex-end;
  }
}

@media (max-width: 480px) {
  .header-logged-in.media-item,
  .header-content {
    max-width: 100%;
    border-radius: 0;
  }

  .header-content {
    padding: 15px;
  }

  .app-name {
    font-size: 1.2rem;
  }

  .header-right .menu-button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.header-center {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  justify-content: center;
}

.notification-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: 80px;
  height: 40px;
  position: relative;
}

.notification-button:hover {
  filter: brightness(125%);
}

.notification-button .fa-icon {
  font-size: 1.2rem;
  margin-bottom: 4px;
}

.notification-button span {
  font-size: 0.7rem;
  text-align: center;
}

.notification-count {
  background-color: #ff4d4d;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.7rem;
  position: absolute;
  top: -5px;
  right: -5px;
}

/* Specific colors for each button */
.notification-button.hate-speech {
  background-color: #e74c3c;
}

.notification-button.negativity {
  background-color: #f39c12;
}

.notification-button.questions {
  background-color: #3498db;
}

.notification-button.unanswered {
  background-color: #656667;
}