/* Resetowanie marginesów i ustawienie czcionki */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffb3c2;
  color: #333; /* Ciemniejszy kolor dla lepszej czytelności */
}

.dashboard {
  padding: 20px;
  background-color: #e4dbd8;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  max-width: 1200px; /* Maksymalna szerokość nagłówka */
  margin: 0 auto; /* Wyśrodkowanie nagłówka */
}

.dashboard-logo {
  height: 40px;
  margin-right: 10px;
}

.dashboard-title {
  margin: 0;
  font-size: 24px;
  color: #fff;
}

.logout-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #e65c50;
}

.media-grid {
  display: grid;
  grid-template-columns: 1fr; /* Zawsze jedno medium w wierszu */
  gap: 30px;
}

.media-item {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row; /* Ustawienie obrazka i komentarzy w jednym wierszu */
  max-width: 1200px; /* Maksymalna szerokość całości */
  margin: 0 auto; /* Wyśrodkowanie całości */
  width: 100%; /* Dodanie stałej szerokości */
}

@media (max-width: 768px) {
  .media-item {
    flex-direction: column; /* Ustawienie obrazka i komentarzy w kolumnie na mniejszych ekranach */
  }
}

.media-content {
  padding: 20px;
  flex: 1;
}

.media-image {
  width: 460px; /* Ustawienie szerokości obrazka lub miniatury wideo na 460px */
  height: auto; /* Zachowanie proporcji */
  border-radius: 10px;
}

.media-caption {
  margin: 10px 0;
  font-size: 14px;
  color: #333; /* Ciemniejszy kolor dla lepszej czytelności */
}

.media-link {
  display: inline-block;
  margin-bottom: 10px;
  color: #0095f6;
  text-decoration: none;
}

.comments-section {
  padding: 20px;
  flex: 0 0 700px; /* Stała szerokość sekcji komentarzy */
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Upewnienie się, że padding jest wliczany do szerokości */
}

@media (max-width: 768px) {
  .comments-section {
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1); /* Dodanie górnej granicy na mniejszych ekranach */
    flex: 1; /* Sekcja komentarzy zajmuje całą szerokość */
  }
}

.comments-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.comments-list {
  list-style-type: none;
  padding-left: 0;
}

.replies-list {
  margin-left: 20px;
  border-left: 2px solid #e0e0e0;
  padding-left: 10px;
}

.comment {
  margin-bottom: 15px;
}

.reply {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.comment-username {
  font-weight: bold;
  color: #333;
}

.comment-timestamp {
  color: #666;
  font-size: 0.8em;
}

.comment-text {
  margin: 0;
  color: #333;
  font-size: 0.9em;
}

.comment-actions {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.action-button {
  background-color: #ff6f61;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  color: #fff;
  transition: background-color 0.2s;
}

.action-button:hover {
  background-color: #e65c50;
}

.suggested-reply-container {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.suggested-reply-textarea {
  width: 100%;
  min-height: 80px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 10px;
  font-size: 0.9em;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
}

.send-reply-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  transition: background-color 0.2s;
}

.send-reply-button:hover {
  background-color: #0056b3;
}

.error {
  color: #dc3545;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}

.loading {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #6c757d;
}

.replies-container {
  margin-top: 10px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-comment {
  animation: slideIn 0.5s ease-out;
}

.highlighted-comment {
  background-color: #ffe6e6;
  border-left: 3px solid #ff4d4d;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading-text {
  color: white;
  margin-top: 20px;
  font-size: 18px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}