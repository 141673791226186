.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #262626;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    color: #8e8e8e;
    margin-bottom: 2rem;
  }
  
  .instagram-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0095f6;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .instagram-login-button:hover {
    background-color: #0077c5;
  }
  
  .instagram-login-button svg {
    margin-right: 10px;
  }